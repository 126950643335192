<template>
  <ion-header>
    <ion-row>
      <ion-col size="9" class="ion-padding-top">
        <ion-title size="medium"> NewTicket</ion-title>
      </ion-col>
      <ion-col class="ion-align-items-center ion-margin-top">
        <ion-icon
          class="arrow-down"
          @click="closeModal"
          :icon="close"
        ></ion-icon>
      </ion-col>
    </ion-row>
  </ion-header>
  <ion-content class="ion-no-padding">
    <div class="page">
      <ion-card class="form no-background">
        <ion-list lines="none">
          <ion-item class="ion-margin-top">
            <ion-label position="stacked">Oggetto</ion-label>
            <ion-input
              v-model="inputOggetto"
              inputmode="text"
              maxlength="50"
              required
              placeholder="Scrivi l'oggetto..."
            ></ion-input>
          </ion-item>
          <ion-row class="ion-justify-content-end">
            {{ inputOggetto.length }}/ 50
          </ion-row>
          <ion-item class="ion-margin-top">
            <ion-label position="stacked">Tipo di problema</ion-label>
            <ion-select
              interface="action-sheet"
              placeholder="Seleziona il tipo di problema..."
              v-model="selectedOption"
            >
              <ion-select-option
                v-for="option in options"
                :key="option"
                :value="option.value"
              >
                {{ option.text }}
              </ion-select-option>
            </ion-select>
          </ion-item>
          <br />
          <ion-item class="ion-margin-top">
            <ion-label position="stacked">Descrizione del problema</ion-label>
            <ion-textarea
              autoGrow
              autocapitalize
              v-model="description"
              placeholder="Scrivi la descrizione del problema..."
            ></ion-textarea>
          </ion-item>
        </ion-list>
      </ion-card>
      <div class="post-pic">
        <div class="input">
          <label>
            <ion-icon
              :icon="camera"
              @click="addFiles()"
              class="camera-icon"
            ></ion-icon>
          </label>
          <input type="file" ref="files" @change="handleFilesUpload($event)" />
        </div>
        <img
          v-if="previewImgUrl"
          :src="previewImgUrl"
          @error="handleImgError"
        />
      </div>
    </div>
  </ion-content>
  <div class="flex justify-content-center p-2">
    <button class="px-4 py-2 button-primary" @click="sendTicket">Invia</button>
  </div>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonLabel,
  IonInput,
  IonSelect,
  IonSelectOption,
  IonList,
  IonItem,
  IonTextarea,
  IonCard,
  modalController,
} from "@ionic/vue";
import { close, camera } from "ionicons/icons";
import UserService from "../../common/mixins/User";

import ApiService from "../../common/service.api";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
    IonLabel,
    IonInput,
    IonSelect,
    IonSelectOption,
    IonList,
    IonItem,
    IonTextarea,
    IonCard,
  },
  name: "ModalNewTicket",

  props: {
    options: Array,
  },
  data() {
    return {
      inputOggetto: "",
      selectedOption: 0,
      description: "",
      previewImgUrl: "",
      files: [],
      user: null,
      newTicketID: 0,
      //files
      close,
      camera,
    };
  },
  mixins: [UserService],
  created() {
    this.getCurrentUser().then((res) => {
      this.user = res;
    });
  },
  methods: {
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    closeModal() {
      return modalController.dismiss();
    },
    sendTicket() {
      let ticket = {
        data: new Date(),
        titolo: this.inputOggetto,
        descrizione: this.description,
        created_by: this.user.id,
        created_by_string: this.user.nome + " " + this.user.cognome,
        id_type: this.selectedOption,
        id_status: 1,
        need_reply: true,
        solved: false,
      };
      ApiService.post("HelpDesk", ticket).then((res) => {
        if (res) {
          console.log("TICKET INVIATO", res);
          this.newTicketID = res.data.id;
          this.closeModal();
          this.sendMessage();
        }
      });
    },

    addFiles() {
      this.$refs.files.click();
    },
    handleFilesUpload(e) {
      const file = e.target.files[0];

      this.previewImgUrl = URL.createObjectURL(file);
      this.files.push(file);
    },
    sendMessage() {
      const message = {
        id: null,
        data: new Date().toJSON(),
        message: this.description,
        userType: "U",
        id_user: this.user.id,
        files: [],
      };
      const formData = new FormData();
      formData.append("file", this.files);

      const promises = [];

      for (let i = 0; i < this.files.length; i++) {
        const formData = new FormData();
        formData.append("file", this.files[i]);
        promises.push(
          ApiService.post("Upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
        );
      }

      Promise.all(promises).then((values) => {
        values.map((res) => {
          console.log("--- FILE UPLOADED ---");
          message.files.push({ file_url: res.data });
        });
        ApiService.post("HelpDeskChat?idTicket=" + this.newTicketID, message)
          .then((res) => {
            if (res) {
              console.log(res);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
  },
});
</script>

<style scoped>
/* * {
  border: 1px solid greenyellow;
} */

ion-item {
  border: 1px solid rgb(172, 170, 170);
  border-radius: 15px;
}

ion-icon {
  font-size: 2rem;
  margin-bottom: 8px;
  color: rgb(141, 141, 141);
}

.arrow-down {
  margin: 8px;
}

.post-pic {
  /* position: relative; */
  display: grid;
  place-items: center;
  padding: 0.5rem;
  width: 100%;
  min-height: 30px;
}

.post-pic img {
  width: 100%;
  height: auto;
  filter: opacity(0.5);
  border-radius: 15px;
}

.camera-icon {
  margin-top: 3rem;
  color: var(--ion-color-primary);
  font-size: 6rem;
}

input[type="file"] {
  position: absolute;
  top: -500px;
}

ion-content {
  --background: var(--client-ground2-color);
}
.page {
  background: var(--client-ground2-color);
}

ion-content {
  --background: white !important;
}

.page {
  background: white;
}
</style>
